
import { mapState } from "vuex";
export default {
	data () {
		return {
		}
	},
	computed: {
		...mapState({
			userInfo: (state) => state.user.userInfo,
		}),
		is_myself () {
			const uuid = this.$route.query.uuid
			if (uuid) {
				if (this.userInfo) {
					return uuid == this.userInfo.uuid
				} else {
					return false
				}
			} else {
				return false
			}
		}
	}
}